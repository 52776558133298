<template>
   <div class="obi-content">
      <div class="obi-content--sidebar">
         <slot name="sidebar"></slot>
      </div>
      <div class="obi-content--content" :style="isAdmin ? 'margin-top:0px' : 'margin-top:-82px;'">
         <slot name="content"></slot>
      </div>
   </div>
</template>

<script>
export default {
   name: 'ObiContent',
   props: {
      isAdmin: {
         type: [Boolean],
         default: false,
      },
   },
};
</script>
<style lang="scss" scoped>
.obi-content {
   display: grid;
   grid-gap: 15px;
   grid-auto-flow: column;
   background-color: #f1f1f1;
   grid-template-columns: auto 1fr;

   .obi-content--sidebar {
      background-color: #f6f6f6;
   }
}
</style>
