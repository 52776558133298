import { render, staticRenderFns } from "./ObiContent.vue?vue&type=template&id=0977b040&scoped=true&"
import script from "./ObiContent.vue?vue&type=script&lang=js&"
export * from "./ObiContent.vue?vue&type=script&lang=js&"
import style0 from "./ObiContent.vue?vue&type=style&index=0&id=0977b040&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0977b040",
  null
  
)

export default component.exports